import React, { useEffect, useState, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import * as AiIcons from 'react-icons/ai';
import * as FiIcons from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';

import {
  Button, Container, ContainerMobile,
  CoresDiv, CorTamanhoDiv, DescricaoProdutoDiv,
  DetalhesDiv, EsgotadoText, FreteDiv,
  FreteInput, FreteInputDiv, ImageCarouselContainer,
  ImageCarouselDiv, MultiFreteDiv, NavDiv,
  NavDivCarrinho, PaletaCoresDiv, PaletaTamanhosDiv,
  PrecoDiv, ProdutoInfoDiv, QuantidadeButton,
  QuantidadeInput, QuantidadeInputDiv, Ref,
  TamanhosDiv, Titulo
} from './styles';

import Footer from '../../components/Footer';
import Accordion from '../../components/Accordion';
import Cores from '../../components/Cores';
import Tamanhos from '../../components/Tamanhos';
import Context, { ICarrinho, IContext, ICorSelecionada } from '../../context/Context';
import AvaliacaoProduto from '../../components/AvaliacaoProduto';

import api from '../../services/api';
import { formatCurrency } from '../../utils/formatCurrency';
import useWindowDimensions from '../../utils/WindowDimensions';
import { BuscaEndereco } from '../../utils/buscaCep';
import semFoto from '../../assets/images/sem-foto.jpg';
import { gtmEventPush } from '../../utils/gtmEventPush';

export interface IIteCar {
  cod: number | undefined;
  codmer: number;
  codapp_user: number;
  qua: number;
  estatu?: number;
}

export interface IMultiFrete {
  codigo: number;
  tipo: string;
  logoEmpresa: string;
  valor: string;
  prazo: string;
  empresa: string;
}

export interface ItamanhosProd {
  tamanho: string;
  selecionado: boolean;
}

export interface ICoresProd {
  cod: string;
  padmer: string;
  linkFot: string;
  selecionado: boolean;
}

interface ICor {
  padmer: string;
  cod: string;
  linimacor: string
}

export async function postItemCarrinho({ cod, codmer, codapp_user, qua }: IIteCar) {
  try {
    const payload = { cod, codmer, codapp_user, qua };

    if (codapp_user === 0) return;

    const response = await api.post('/itecar/salvar', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    console.log('Falha ao salvar carrinho na nuvem.' + error.message);
  }
}

export async function deleteItemCarrinho(cod: number | undefined) {
  try {
    if (!cod) return;
    await api.delete(`/itecar/delete?id=${cod}`);
  } catch (error: any) {
    console.log('Falha ao apagar item do carrinho na nuvem.' + error.message);
  }
}

export default function ProdutoDetalhes() {
  const { codbar } = useParams();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;
  const pularTutorial = localStorage.getItem('@Acessado');

  const {
    carrinho, setCarrinho, configs,
    dadosLogin, setSideBarCarrinhoIsOpen
  }: IContext = useContext(Context);

  const [quantidade, setQuantidade] = useState<string>('1');
  const [produtosGrade, setProdutosGrade] = useState<ICarrinho[]>([]);

  const [corSelecionada, setCorSelecionada] = useState<ICorSelecionada>({ cod: '', padmer: '', linkFot: '' });
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState<string>('');
  const [valorGradeSelecionado, setValorGradeSelecionado] = useState<number>(0);
  const [fotos, setFotos] = useState<any>([]);
  const [produtoDetalhes, setProdutoDetalhes] = useState<any>();
  const [desSit, setDesSit] = useState<any>([]);

  const [tamanhos, setTamanhos] = useState<ItamanhosProd[]>([]);
  const [cores, setCores] = useState<ICoresProd[]>([]);
  const [indexCarousel, setIndexCarousel] = useState<number>(0);

  const [runTutorial, setRunTutorial] = useState(false);

  //configs
  const [quaMaxPar, setQuaMaxPar] = useState(1);
  const [valMinPar, setValMinPar] = useState(1);
  const [keyApiGoo, setKeyApiGoo] = useState<string>('0');
  const [numCel, setNumCel] = useState<string>('');
  const [necCadAutMosPro, setNecCadAutMosPro] = useState<boolean>(false);
  const [venAciEst, setVenAciEst] = useState<boolean>(true);
  const [usaGra, setUsaGra] = useState<boolean>(false);
  const [botFinCom, setBotFinCom] = useState<boolean>(false);

  //frete
  const [fre, setFre] = useState<undefined | number>();
  const [mulFre, setMulFre] = useState<IMultiFrete[]>([]);
  const [cepFre, setCepFre] = useState<string>('');

  const steps = [
    {
      target: '.buttonFavoritar-step',
      content: 'Clique no botão para salvar esse produto na sua lista de desejos',
      disableBeacon: true,
      title: 'Tô aqui pra te ajudar 😜',
    },
    {
      target: '.cores-step',
      content: 'Aqui você escolhe a cor que deseja!',
      disableBeacon: true,
      title: 'Cores disponíveis',
    },
    {
      target: '.tamanhos-step',
      content: 'Aqui você escolhe o tamanho!',
      disableBeacon: true,
      title: 'Tamanhos disponíveis',
    },
    {
      target: '.quantidade-step',
      content: 'Informe a quantidade desejada',
      disableBeacon: true,
      title: 'Quantidade',
    },
    {
      target: '.buttonCarrinho-step',
      content: 'Depois de tudo, só dar um clique aqui',
      disableBeacon: true,
      title: 'Mandar pro Carrinho 👌',
    },
  ];

  async function getProdutoDetalhes(codbar: string) {
    try {
      const response = await api.get(`/mercador/listarParaDetalhes?codbar=${codbar}&CODTABPRE=0`);
      if (response.status === 200) {
        const tamanhosProd: ItamanhosProd[] = response.data.tamanhos.map((tamanho: string) => {
          return { tamanho: tamanho, selecionado: false };
        });

        const coresProd: ICoresProd[] = response.data.cores.map((cor: ICor) => {
          const corPorFoto = response.data.fotos.filter((foto: any) => foto.codpad === cor.cod);
          return { cod: cor.cod, padmer: cor.padmer, linkFot: corPorFoto[0]?.linkfot ? 'https://' + corPorFoto[0]?.linkfot : semFoto, selecionado: false };
        });

        setTamanhos(tamanhosProd);
        setCores(coresProd);
        setFotos(response.data?.fotos);
        setProdutoDetalhes(response.data);
        setDesSit([
          { titulo: 'Descrição do Produto', conteudo: response.data?.desSit }
        ]);
        setRunTutorial(true);
      }

    } catch (error: any) {
      toast.error('Falha ao buscar detalhes do produto ' + error.message);
    }
  }

  function decrementarQuantidade() {
    if (+quantidade <= 1) {
      return;
    }
    setQuantidade(prev => {
      return String(+prev - 1);
    });
  }

  function incrementarQuantidade() {
    setQuantidade(prev => {
      return String(+prev + 1);
    });
  }

  function alterarQuantidade(e: React.ChangeEvent<HTMLInputElement>) {
    if (+e.target.value >= 0) {
      setQuantidade(String(Math.round(+e.target.value)));
    }
  }

  async function addProdutoNoCarrinho({ codmer, codbar, mer, codtam, cor, quantidade, valor, estatu }: ICarrinho) {
    const novoProduto: ICarrinho[] = [{ codmer, codbar, mer, codtam, cor, quantidade, valor, estatu }];

    if (quantidade == '0') {
      toast.warning('Quantidade inválida');
      return;
    }

    if (produtoDetalhes?.tamanhos.length > 0 && !codtam) {
      toast.warning('Selecione o tamanho');
      return;
    }

    if (produtoDetalhes?.cores.length > 0 && !cor.cod) {
      toast.warning('Selecione a cor');
      return;
    }

    if (!valor) {
      toast.warning('Preço do produto está inválido, entre em contato com a loja.');
      return;
    }

    if (produtoDetalhes?.cores.length === 0) {
      novoProduto[0]['cor'] = {
        cod: '', padmer: 'ÚNICA',
        linkFot: fotos[0]?.linkfot ? 'https://' + fotos[0]?.linkfot : semFoto
      };
    }

    if (!codmer) {
      toast.error('Código do produto não encontrado');
      return;
    }

    if (!venAciEst) {
      if (estatu === 0) {
        toast.warning('Produto fora de estoque. Estoque atual: ' + estatu);
        return;
      }

      if (!estatu) {
        toast.warning('Não foi possível verificar estoque.');
        return;
      }

      if (estatu < +quantidade) {
        toast.warning('Quantidade indisponível no estoque. Estoque atual: ' + estatu);
        return;
      }
    }

    const carrinhoAtual = carrinho;
    const [produtoJaEstaNoCarrinho] = carrinho.filter((item: ICarrinho) => item.codmer === codmer);

    if (produtoJaEstaNoCarrinho) {
      const index = carrinhoAtual.indexOf(produtoJaEstaNoCarrinho);

      if (!venAciEst) {
        if (estatu === 0) {
          toast.warning('Produto fora de estoque. Estoque atual: ' + estatu);
          return;
        }

        if (!estatu) {
          toast.warning('Não foi possível verificar estoque.');
          return;
        }

        if ((+quantidade + +(carrinhoAtual[index].quantidade)) > estatu) {
          toast.warning(`Quantidade indisponível no estoque. Já tem ${carrinhoAtual[index].quantidade} unidades no seu carrinho. Estoque atual: ${estatu}`);
          return;
        }
      }

      carrinhoAtual[index]['quantidade'] = String(+(carrinhoAtual[index].quantidade) + Math.floor(+(quantidade)));

      const itemCarrinhoAtualizado = {
        cod: carrinhoAtual[index].cod, codmer: carrinhoAtual[index].codmer,
        codapp_user: dadosLogin.id, qua: +carrinhoAtual[index].quantidade, estatu
      };

      postItemCarrinho(itemCarrinhoAtualizado);
      setCarrinho(carrinhoAtual);
      setSideBarCarrinhoIsOpen(true);

      gtmEventPush('add_to_cart', {
        ecommerce: {
          currency: 'BRL',
          value: novoProduto[0].valor * +novoProduto[0].quantidade,
          items: {
            item_id: novoProduto[0].codmer,
            item_name: novoProduto[0].mer,
            sku: novoProduto[0].codbar,
            price: novoProduto[0].valor.toString(),
            item_variant: `${novoProduto[0].codbar}${novoProduto[0].cor?.padmer && novoProduto[0].cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto[0].cor.padmer : ''}${novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''}`,
            item_brand: '',
            quantity: novoProduto[0].quantidade
          }
        },
        eventModel: {
          item_id: novoProduto[0].codmer,
          item_name: novoProduto[0].mer,
          sku: novoProduto[0].codbar,
          price: novoProduto[0].valor.toString(),
          item_variant: `${novoProduto[0].codbar}${novoProduto[0].cor?.padmer && novoProduto[0].cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto[0].cor.padmer : ''}${novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''}`,
          item_brand: '',
          quantity: novoProduto[0].quantidade
        }
      });
      return;
    }

    const novoItemCarrinho: IIteCar = { cod: undefined, codmer: novoProduto[0].codmer, codapp_user: dadosLogin.id, qua: +novoProduto[0].quantidade, estatu };

    const iteCarSalvo = await postItemCarrinho(novoItemCarrinho);

    if (iteCarSalvo) {
      novoProduto[0]['cod'] = iteCarSalvo.cod;
    }

    setCarrinho([...carrinho, ...novoProduto]);
    setSideBarCarrinhoIsOpen(true);

    gtmEventPush('add_to_cart', {
      ecommerce: {
        currency: 'BRL',
        value: novoProduto[0].valor,
        items: {
          item_id: novoProduto[0].codmer,
          item_name: novoProduto[0].mer,
          sku: novoProduto[0].codbar,
          price: novoProduto[0].valor.toString(),
          item_variant: `${novoProduto[0].codbar}${novoProduto[0].cor?.padmer && novoProduto[0].cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto[0].cor.padmer : ''}${novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''}`,
          item_brand: '',
          quantity: '1'
        }
      },
      eventModel: {
        item_id: novoProduto[0].codmer,
        item_name: novoProduto[0].mer,
        sku: novoProduto[0].codbar,
        price: novoProduto[0].valor.toString(),
        item_variant: `${novoProduto[0].codbar}${novoProduto[0].cor?.padmer && novoProduto[0].cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto[0].cor.padmer : ''}${novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''}`,
        item_brand: '',
        quantity: '1'
      }
    });
  }

  async function addProdutosGradeNoCarrinho(produtosGrade: ICarrinho[]) {
    const carrinhoAtualizado = [...carrinho];

    for (const produtoGrade of produtosGrade) {
      const novoProduto: ICarrinho = { ...produtoGrade };

      if (novoProduto.quantidade === '0') {
        toast.warning('Quantidade inválida');
        continue;
      }

      if (produtoDetalhes?.tamanhos.length > 0 && !novoProduto.codtam) {
        toast.warning('Selecione o tamanho');
        continue;
      }

      if (produtoDetalhes?.cores.length > 0 && !novoProduto.cor.cod) {
        toast.warning('Selecione a cor');
        continue;
      }

      if (!novoProduto.valor) {
        toast.warning('Preço do produto está inválido, entre em contato com a loja.');
        continue;
      }

      if (produtoDetalhes?.cores.length === 0) {
        novoProduto.cor = {
          cod: '', padmer: 'ÚNICA',
          linkFot: fotos[0]?.linkfot ? 'https://' + fotos[0]?.linkfot : semFoto
        };
      }

      if (!novoProduto.codmer) {
        toast.error('Código do produto não encontrado');
        continue;
      }

      if (!venAciEst) {
        if (novoProduto.estatu === 0) {
          toast.warning('Produto fora de estoque. Estoque atual: ' + novoProduto.estatu);
          continue;
        }

        if (!novoProduto.estatu) {
          toast.warning('Não foi possível verificar estoque.');
          continue;
        }

        if (novoProduto.estatu < +novoProduto.quantidade) {
          toast.warning('Quantidade indisponível no estoque. Estoque atual: ' + novoProduto.estatu);
          continue;
        }
      }

      const produtoJaEstaNoCarrinho = carrinhoAtualizado.find((item: ICarrinho) => item.codmer === novoProduto.codmer);

      if (produtoJaEstaNoCarrinho) {
        const index = carrinhoAtualizado.indexOf(produtoJaEstaNoCarrinho);

        if (!venAciEst) {
          if (novoProduto.estatu === 0) {
            toast.warning('Produto fora de estoque. Estoque atual: ' + novoProduto.estatu);
            continue;
          }

          if (!novoProduto.estatu) {
            toast.warning('Não foi possível verificar estoque.');
            continue;
          }

          if ((+novoProduto.quantidade + +(carrinhoAtualizado[index].quantidade)) > novoProduto.estatu) {
            toast.warning(`Quantidade indisponível no estoque. Já tem ${carrinhoAtualizado[index].quantidade} unidades no seu carrinho. Estoque atual: ${novoProduto.estatu}`);
            continue;
          }
        }

        carrinhoAtualizado[index].quantidade = String(+(carrinhoAtualizado[index].quantidade) + Math.floor(+(novoProduto.quantidade)));

        const itemCarrinhoAtualizado = {
          cod: carrinhoAtualizado[index].cod, codmer: carrinhoAtualizado[index].codmer,
          codapp_user: dadosLogin.id, qua: +carrinhoAtualizado[index].quantidade, estatu: novoProduto.estatu
        };

        await postItemCarrinho(itemCarrinhoAtualizado);

        gtmEventPush('add_to_cart', {
          ecommerce: {
            currency: 'BRL',
            value: novoProduto.valor * +novoProduto.quantidade,
            items: [{
              item_id: novoProduto.codmer,
              item_name: novoProduto.mer,
              sku: novoProduto.codbar,
              price: novoProduto.valor.toString(),
              item_variant: `${novoProduto.codbar}${novoProduto.cor?.padmer && novoProduto.cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto.cor.padmer : ''}${novoProduto.codtam ? ' - ' + novoProduto.codtam : ''}`,
              item_brand: '',
              quantity: novoProduto.quantidade
            }]
          },
          eventModel: {
            item_id: novoProduto.codmer,
            item_name: novoProduto.mer,
            sku: novoProduto.codbar,
            price: novoProduto.valor.toString(),
            item_variant: `${novoProduto.codbar}${novoProduto.cor?.padmer && novoProduto.cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto.cor.padmer : ''}${novoProduto.codtam ? ' - ' + novoProduto.codtam : ''}`,
            item_brand: '',
            quantity: novoProduto.quantidade
          }
        });
      } else {
        const novoItemCarrinho: IIteCar = { cod: undefined, codmer: novoProduto.codmer, codapp_user: dadosLogin.id, qua: +novoProduto.quantidade, estatu: novoProduto.estatu };
        const iteCarSalvo = await postItemCarrinho(novoItemCarrinho);

        if (iteCarSalvo) {
          novoProduto.cod = iteCarSalvo.cod;
        }

        carrinhoAtualizado.push(novoProduto);

        gtmEventPush('add_to_cart', {
          ecommerce: {
            currency: 'BRL',
            value: novoProduto.valor,
            items: [{
              item_id: novoProduto.codmer,
              item_name: novoProduto.mer,
              sku: novoProduto.codbar,
              price: novoProduto.valor.toString(),
              item_variant: `${novoProduto.codbar}${novoProduto.cor?.padmer && novoProduto.cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto.cor.padmer : ''}${novoProduto.codtam ? ' - ' + novoProduto.codtam : ''}`,
              item_brand: '',
              quantity: '1'
            }]
          },
          eventModel: {
            item_id: novoProduto.codmer,
            item_name: novoProduto.mer,
            sku: novoProduto.codbar,
            price: novoProduto.valor.toString(),
            item_variant: `${novoProduto.codbar}${novoProduto.cor?.padmer && novoProduto.cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto.cor.padmer : ''}${novoProduto.codtam ? ' - ' + novoProduto.codtam : ''}`,
            item_brand: '',
            quantity: '1'
          }
        });
      }
    }

    setCarrinho(carrinhoAtualizado);
    setSideBarCarrinhoIsOpen(true);
  }

  function tutorialCallback(data: CallBackProps) {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunTutorial(false);
      localStorage.setItem('@Acessado', 'true');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  function ComprarPeloWhatsApp() {
    const produtos = `Ref: ${codbar} ` + produtoDetalhes?.mer + ` Tamanho: ${tamanhoSelecionado}` + ` Cor: ${corSelecionada.padmer}` +
      ` Qtde: ${quantidade}` + ` Vlr Unitário: ${formatCurrency(produtoDetalhes?.valVenMin)}` + '%0A' + 'https://' + fotos[indexCarousel].linkfot;

    const url = 'https://api.whatsapp.com/send?phone=55' + numCel.replace(/\D/g, '') + '&text=Olá!! Vim pelo site e estou interessado no item:' + '%0A' + produtos;

    window.open(url);
  }

  async function adicionarListaDeDesejo() {
    try {
      if (dadosLogin.id === 0) {
        toast.warning('Faça login para montar sua lista de desejos');
        navigate('/login');
        return;
      }

      const response = await api.get(`/itelisdes/listarPorUsuario?id=${dadosLogin.id}`);
      let produtoJaEstaNaLista = [];

      if (response.data !== 'Produto não encontrado') {
        produtoJaEstaNaLista = response.data.content.filter((e: any) => e.codBar === codbar);
      }

      if (produtoJaEstaNaLista.length > 0) {
        await api.delete(`/itelisdes/delete?id=${produtoJaEstaNaLista[0].codIteLisDes}`);
        toast.success(`${produtoDetalhes?.mer} foi removido da sua lista de desejos.`);
        return;
      }

      const payload = {
        codmer: produtoDetalhes.detalhes[0].codigo,
        codapp_user: dadosLogin.id
      };

      await api.post('/itelisdes/salvar', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      toast.success(`${produtoDetalhes?.mer} foi adicionado a sua lista de desejos.`);

    } catch (error: any) {
      toast.error('Falha ao salvar na lista de desejos. ' + error.message);
    }
  }

  async function calculaFrete() {
    try {
      if (cepFre.length < 8) {
        toast.warning('Cep infomado é inválido');
        return;
      }

      setFre(undefined);
      setMulFre([]);

      if (keyApiGoo !== '0') {
        const dadosEndereco = await BuscaEndereco(cepFre);

        const destino = dadosEndereco.logradouro + ', ' +
          dadosEndereco.num + ' - ' + dadosEndereco.bairro + ', ' + dadosEndereco.localidade + ' - ' +
          dadosEndereco.uf + ', ' + dadosEndereco.cep;

        const response = await api.get(`/pedidos/CalcularDistanciaParaEntregar?destino=${destino}`);

        if (response.status === 200) {
          if (response.data.length > 0) {
            setFre(+(response.data[0].valor.replace(',', '.')));
            return;
          }
          return;
        }
        return;
      }

      const codmer = produtoDetalhes?.detalhes.filter((produto: any) =>
        produto.tamanho == tamanhoSelecionado && (produto?.cor ? produto.cor === corSelecionada.padmer : true)
      );

      const payload = {
        cep: cepFre,
        itens: [{
          codmer: codmer[0]?.codigo || produtoDetalhes?.detalhes[0].codigo,
          qua: +quantidade
        }]
      };

      const response = await api.post('/mercador/calcularParaFrete', payload);

      if (response.data.includes('Unauthorized')) {
        throw Error('Melhor Envio não autorizado.');
      }

      if (response.status === 200 && Array.isArray(response.data)) {
        setMulFre(response.data);
      }

    } catch (error: any) {
      toast.error('Falha ao calcular frete. ' + error.message);
    }
  }

  function handleDisableGradeInput(tamanho: string | undefined, cor: string | undefined): boolean {
    let produtoDetalhe: any[] = [];

    if (tamanho && cor) {
      produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.tamanho === tamanho && detalhe.cor === cor);
    }

    if (tamanho && !cor) {
      produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.tamanho === tamanho);
    }

    if (!tamanho && cor) {
      produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.cor === cor);
    }

    if (produtoDetalhe.length > 0 && produtoDetalhe[0].estoque > 0) return false;
    return true;
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    getProdutoDetalhes(codbar ?? '');
  }, []);

  useEffect(() => {
    if (produtoDetalhes) {
      gtmEventPush('view_item', {
        ecommerce: {
          currency: 'BRL',
          value: produtoDetalhes?.valVenMin || 0,
          items: [{
            item_id: produtoDetalhes?.detalhes[0]?.codigo,
            item_name: produtoDetalhes?.mer,
            sku: codbar,
            price: produtoDetalhes?.valVenMin || 0,
            item_category: '',
            id: produtoDetalhes.detalhes[0].codigo
          }]
        }
      });
    }
  }, [produtoDetalhes]);

  useEffect(() => {
    if (produtoDetalhes?.fotos) {
      const fotosCorSelecionada = produtoDetalhes.fotos.filter((foto: any) => foto.codpad === corSelecionada.cod);
      if (fotosCorSelecionada.length > 0) {
        setFotos(fotosCorSelecionada);
        setIndexCarousel(0);
        return;
      }
      setFotos([{
        codpad: corSelecionada.cod, linkfot: 'https://www.quitandadelivery.com/images/geral/sem_foto_big.jpg'
      }]);
      setIndexCarousel(0);
      return;
    }
  }, [corSelecionada]);

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: quaMaxPar }] = configs.filter((config: any) => config.con === 'quamaxpar');
      const [{ val: valminpar }] = configs.filter((config: any) => config.con === 'valminpar');
      const [{ val: keyApiGoo }] = configs.filter((config: any) => config.con === 'KeyApiGoo');
      const [{ val: numWha }] = configs.filter((config: any) => config.con === 'NumWha');
      const [{ val: CadAutMosPro }] = configs.filter((config: any) => config.con === 'NecCadAutMosPro');
      const [{ val: VenAciEst }] = configs.filter((config: any) => config.con === 'VenAciEst');
      const [{ val: UsaGra }] = configs.filter((config: any) => config.con === 'UsaGra');
      const [{ val: botFinCom }] = configs.filter((config: any) => config.con === 'botFinCom');

      setQuaMaxPar(+quaMaxPar);
      setValMinPar(+valminpar);
      setKeyApiGoo(keyApiGoo);
      setNumCel(numWha);
      setNecCadAutMosPro(Boolean(+CadAutMosPro));
      setVenAciEst(Boolean(+VenAciEst));
      setUsaGra(Boolean(+UsaGra));
      setBotFinCom(Boolean(JSON.parse(botFinCom ?? 0)));
    }
  }, [configs]);

  function handleGradeChange(event: React.ChangeEvent<HTMLInputElement>, cor: string | undefined, tamanho: string | undefined) {
    const { value } = event.target;

    let produtoDetalhe = [];

    if (tamanho && cor) {
      produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.tamanho === tamanho && detalhe.cor === cor);
    }

    if (tamanho && !cor) {
      produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.tamanho === tamanho);
    }

    if (!tamanho && cor) {
      produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.cor === cor);
    }

    if (!value || +value <= 0) {
      setProdutosGrade((prev) => prev.filter((item) => item.codmer !== produtoDetalhe[0].codigo));
      return;
    }

    const produtoGradeJaExistente = produtosGrade.filter((item) => item.codmer === produtoDetalhe[0].codigo);

    if (produtoGradeJaExistente.length > 0) {
      setProdutosGrade((prev) => prev.map((item) => {
        if (item.codmer === produtoDetalhe[0].codigo) return { ...item, quantidade: String(Math.floor(+value)) };
        return item;
      }));

      return;
    }

    const novoProdutoGrade: ICarrinho[] = [{
      codmer: produtoDetalhe[0].codigo,
      codbar: produtoDetalhe[0].codbar,
      mer: produtoDetalhes?.mer,
      codtam: produtoDetalhe[0].tamanho,
      cor: corSelecionada,
      quantidade: String(Math.floor(+value)),
      valor: produtoDetalhe[0].valor,
      estatu: produtoDetalhe[0].estoque
    }];

    setProdutosGrade((prev) => [...prev, ...novoProdutoGrade]);
  }

  return (
    <>
      {!pularTutorial && <Joyride
        run={runTutorial}
        steps={steps}
        callback={tutorialCallback}
        continuous
        hideCloseButton
        showSkipButton
        disableScrolling={!isMobile}
        scrollOffset={70}
        locale={{
          back: 'Voltar', close: 'Fechar', last: 'Fechar',
          next: 'Próximo', open: 'Abrir caixa', skip: 'Pular'
        }}
        styles={{
          options: {
            primaryColor: '#000'
          }
        }}
      />}
      {!isMobile ?
        <Container>
          <DetalhesDiv>
            <ImageCarouselDiv>
              <Carousel
                showArrows={true}
                showStatus={false}
                showThumbs={true}
                swipeable={true}
                emulateTouch={true}
                infiniteLoop
                selectedItem={indexCarousel}
                onChange={setIndexCarousel}
                thumbWidth={fotos.length < 10 ? 60 : 40}
              >
                {fotos.length > 0 && fotos.map((foto: any, index: number) => (
                  <ImageCarouselContainer key={index}>
                    <img
                      src={'https://' + foto.linkfot}
                      alt={`Foto do produto ${produtoDetalhes?.mer}`}
                      onError={() => {
                        setFotos(fotos.filter((f: any) => f.linkfot !== foto.linkfot));
                        // e.target.onerror = null; // Evita loops infinitos caso a imagem de substituição também não seja encontrada
                        // e.target.src = semFoto;
                      }}
                    />
                  </ImageCarouselContainer>
                ))}
              </Carousel>
            </ImageCarouselDiv>
            <ProdutoInfoDiv>
              <NavDiv>
                {/* <span>{!location?.state?.caminho ? 'Home' : location?.state?.caminho}</span> */}
                <span onClick={() => navigate('/')} translate='no'>{'Home'}</span>
                <Button className='buttonFavoritar-step' onClick={adicionarListaDeDesejo}>
                  Marcar como Favorito
                  <AiIcons.AiOutlineHeart style={{ marginLeft: 10 }} size={25} />
                </Button>
              </NavDiv>
              <hr />
              <Titulo>
                {produtoDetalhes?.mer ?? ''}
              </Titulo>
              <Ref>
                Ref: {codbar ?? ''}
              </Ref>
              {produtoDetalhes?.esgSit === 1 && <EsgotadoText>Esgotado</EsgotadoText>}
              {necCadAutMosPro ?
                dadosLogin.autverprosit === 1 ?
                  <PrecoDiv>
                    <b>
                      {produtoDetalhes?.valVenMin !== 0 && produtoDetalhes?.valVenMin !== produtoDetalhes?.valVenMax && valorGradeSelecionado === 0 ? 'A partir de' : ''}
                      {((produtoDetalhes?.valVenMin !== 0 && valorGradeSelecionado === 0) && formatCurrency(produtoDetalhes?.valVenMin || 0)) || ''}
                      {valorGradeSelecionado !== 0 && formatCurrency(valorGradeSelecionado)}
                    </b>
                    {quaMaxPar > 1 && (produtoDetalhes?.valVenMin / quaMaxPar) >= valMinPar &&
                      <span>
                        {quaMaxPar + ' x '}
                        {produtoDetalhes?.valVenMin && formatCurrency(produtoDetalhes?.valVenMin / quaMaxPar)}
                      </span>
                    }
                  </PrecoDiv> : <></> :
                <PrecoDiv>
                  <b>
                    {produtoDetalhes?.valVenMin !== 0 && produtoDetalhes?.valVenMin !== produtoDetalhes?.valVenMax && valorGradeSelecionado === 0 ? 'A partir de ' : ''}
                    {((produtoDetalhes?.valVenMin !== 0 && valorGradeSelecionado === 0) && formatCurrency(produtoDetalhes?.valVenMin || 0)) || ''}
                    {valorGradeSelecionado !== 0 && formatCurrency(valorGradeSelecionado)}
                  </b>
                  {quaMaxPar > 1 && (produtoDetalhes?.valVenMin / quaMaxPar) >= valMinPar &&
                    <span>
                      {quaMaxPar + ' x '}
                      {produtoDetalhes?.valVenMin && formatCurrency(produtoDetalhes?.valVenMin / quaMaxPar)}
                    </span>
                  }
                </PrecoDiv>
              }
              {usaGra ?
                <CorTamanhoDiv>
                  <table>
                    <thead>
                      <tr>
                        <th>Cor/Tamanho</th>
                        {tamanhos.length > 0 ?
                          tamanhos.map((tamanho) => (
                            <th key={tamanho.tamanho}>{tamanho.tamanho}</th>
                          )) :
                          <th>Único</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {cores.length > 0 ?
                        cores.map((cor) => (
                          <tr key={cor.cod}>
                            <td>{cor.padmer}</td>
                            {tamanhos.length > 0 ? tamanhos.map((tamanho) => (
                              <td key={tamanho.tamanho}>
                                <input
                                  type='number'
                                  min='0'
                                  disabled={!venAciEst && handleDisableGradeInput(tamanho.tamanho, cor.padmer)}
                                  onFocus={() => {
                                    setCorSelecionada(cor);

                                    if (produtoDetalhes?.valVenMin !== 0 && produtoDetalhes?.valVenMin !== produtoDetalhes?.valVenMax) {
                                      const produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.tamanho === tamanho.tamanho && detalhe.cor === cor.padmer);
                                      setValorGradeSelecionado(produtoDetalhe[0].valor);
                                    }
                                  }}
                                  onChange={(e) => handleGradeChange(e, cor.padmer, tamanho.tamanho)}
                                />
                              </td>
                            )) :
                              <td>
                                <input
                                  type='number'
                                  min='0'
                                  disabled={!venAciEst && handleDisableGradeInput(undefined, cor.padmer)}
                                  onFocus={() => {
                                    setCorSelecionada(cor);

                                    if (produtoDetalhes?.valVenMin !== 0 && produtoDetalhes?.valVenMin !== produtoDetalhes?.valVenMax) {
                                      const produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.cor === cor.padmer);
                                      setValorGradeSelecionado(produtoDetalhe[0].valor);
                                    }
                                  }}
                                  onChange={(e) => handleGradeChange(e, cor.padmer, undefined)}
                                />
                              </td>
                            }
                          </tr>
                        )) :
                        <tr>
                          <td>Única</td>
                          {tamanhos.map((tamanho) => (
                            <td key={tamanho.tamanho}>
                              <input
                                type='number'
                                min='0'
                                disabled={!venAciEst && handleDisableGradeInput(tamanho.tamanho, undefined)}
                                onFocus={() => {
                                  if (produtoDetalhes?.valVenMin !== 0 && produtoDetalhes?.valVenMin !== produtoDetalhes?.valVenMax) {
                                    const produtoDetalhe = produtoDetalhes.detalhes.filter((detalhe: any) => detalhe.tamanho === tamanho.tamanho);
                                    setValorGradeSelecionado(produtoDetalhe[0].valor);
                                  }
                                }}
                                onChange={(e) => handleGradeChange(e, undefined, tamanho.tamanho)}
                              />
                            </td>
                          ))}
                        </tr>
                      }
                    </tbody>
                  </table>
                </CorTamanhoDiv> :
                <CorTamanhoDiv>
                  {cores.length > 0 &&
                    <CoresDiv className='cores-step'>
                      <span>Cor</span>
                      <PaletaCoresDiv>
                        <Cores setCorSelecionada={setCorSelecionada} coresLista={cores} />
                      </PaletaCoresDiv>
                    </CoresDiv>
                  }
                  {tamanhos.length > 0 &&
                    <TamanhosDiv className='tamanhos-step'>
                      <span>Tamanho</span>
                      <PaletaTamanhosDiv>
                        <Tamanhos setTamanhoSelecionado={setTamanhoSelecionado} tamanhos={tamanhos} />
                      </PaletaTamanhosDiv>
                    </TamanhosDiv>
                  }
                </CorTamanhoDiv>}
              <hr />
              <NavDivCarrinho>
                {botFinCom &&
                  <FreteDiv>
                    <span>
                      CALCULE O FRETE E PRAZO DE ENTREGA
                    </span>
                    <FreteInputDiv>
                      <>
                        <FreteInput
                          placeholder='CEP'
                          value={cepFre} onChange={(e: any) => setCepFre(e.target.value.replace(/\D/g, ''))}
                          onBlur={(e: any) => {
                            if (e.target.value.replace(/\D/g, '').length !== 8) {
                              setCepFre('');
                            }
                          }}
                          maxLength={8}
                        />
                        <FiIcons.FiSearch
                          color='#000'
                          style={{ cursor: 'pointer', height: '100%' }}
                          size={25}
                          onClick={calculaFrete}
                        />
                      </>
                      {fre && <strong>&nbsp;&nbsp;{formatCurrency(fre)}</strong>}
                    </FreteInputDiv>
                    {mulFre && mulFre.map((mulFre: IMultiFrete, index: number) => (
                      <MultiFreteDiv key={index}>
                        <img src={mulFre.logoEmpresa} alt={`Logo envio ${mulFre.empresa}`} />
                        <p>&nbsp;&nbsp;{mulFre.tipo}</p>
                        <p>&nbsp;&nbsp;{mulFre.prazo} dias úteis</p>
                        <strong>&nbsp;&nbsp;{formatCurrency(+mulFre.valor)}</strong>
                      </MultiFreteDiv>
                    ))}
                  </FreteDiv>
                }
                {!usaGra ?
                  <QuantidadeInputDiv className='quantidade-step'>
                    <QuantidadeButton
                      onClick={decrementarQuantidade}
                    >
                      -
                    </QuantidadeButton>
                    <QuantidadeInput
                      type={'number'}
                      value={quantidade}
                      onChange={alterarQuantidade}
                    />
                    <QuantidadeButton
                      onClick={incrementarQuantidade}>
                      +
                    </QuantidadeButton>
                  </QuantidadeInputDiv> : <span style={{ visibility: 'hidden' }}>.</span>}
                <Button
                  backgroundColor={!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ? '#000' : '#25D366'}
                  onClick={() => {
                    if (import.meta.env.VITE_TITLE.toLowerCase().includes('kilar')) {
                      ComprarPeloWhatsApp();
                      return;
                    }

                    if (usaGra && produtosGrade.length > 0) {
                      addProdutosGradeNoCarrinho(produtosGrade);
                      return;
                    }

                    const codmer = produtoDetalhes?.detalhes.filter((produto: any) =>
                      produto.tamanho == tamanhoSelecionado && (produto?.cor ? produto.cor === corSelecionada.padmer : true)
                    );

                    addProdutoNoCarrinho({
                      codmer: codmer[0]?.codigo ?? '', codbar: produtoDetalhes?.codBar, mer: produtoDetalhes?.mer, codtam: tamanhoSelecionado, cor: corSelecionada,
                      quantidade: String(Math.floor(+quantidade)), valor: codmer[0]?.valor ?? 0, estatu: codmer[0]?.estoque
                    });
                  }}
                  className='buttonCarrinho-step'
                  disabled={Boolean(produtoDetalhes?.esgSit)}
                >
                  {!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ? 'Adicionar ao Carrinho' : 'Compre pelo WhatsApp'}
                  {!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ?
                    <AiIcons.AiOutlineShoppingCart style={{ marginLeft: 10 }} size={25} /> :
                    <AiIcons.AiOutlineWhatsApp style={{ marginLeft: 10 }} size={25} />
                  }
                </Button>
              </NavDivCarrinho>
              <hr />
              <DescricaoProdutoDiv>
                {desSit.length > 0 && desSit.map((descricao: any, index: any) => (
                  <Accordion key={index} titulo={descricao.titulo} texto={descricao.conteudo} />
                ))}
              </DescricaoProdutoDiv>
              <hr />
              <AvaliacaoProduto codbar={codbar} />
            </ProdutoInfoDiv>
          </DetalhesDiv >
        </Container > :
        <ContainerMobile>
          <div style={{ marginLeft: -10 }}>
            <Carousel
              showArrows={true}
              showStatus={false}
              showThumbs={false}
              swipeable={true}
              emulateTouch={true}
              infiniteLoop
              selectedItem={indexCarousel}
              onChange={setIndexCarousel}
            >
              {fotos.length > 0 && fotos.map((foto: any, index: number) => (
                <ImageCarouselContainer key={index}>
                  <img
                    src={'https://' + foto.linkfot}
                    alt={`Foto do produto ${produtoDetalhes?.mer}`}
                    onError={(e: any) => {
                      e.target.onerror = null; // Evita loops infinitos caso a imagem de substituição também não seja encontrada
                      e.target.src = semFoto;
                    }}
                  />
                </ImageCarouselContainer>
              ))}
            </Carousel>
          </div>
          <NavDiv>
            <span onClick={() => navigate('/')} translate='no'>{'Home'}</span>
            <Button className='buttonFavoritar-step' onClick={adicionarListaDeDesejo}>
              Marcar como Favorito
              <AiIcons.AiOutlineHeart style={{ marginLeft: 10 }} size={25} />
            </Button>
          </NavDiv>
          <hr />
          <Titulo>
            {produtoDetalhes?.mer ?? ''} {' '} {corSelecionada?.padmer ?? ''}
          </Titulo>
          <Ref>
            Ref: {codbar ?? ''}
          </Ref>
          {produtoDetalhes?.esgSit === 1 && <EsgotadoText>Esgotado</EsgotadoText>}
          {necCadAutMosPro ?
            dadosLogin.autverprosit === 1 ?
              <PrecoDiv>
                <b>
                  {(produtoDetalhes?.valVenMin !== 0 && formatCurrency(produtoDetalhes?.valVenMin || 0)) || ''}
                </b>
                {quaMaxPar > 1 && (produtoDetalhes?.valVenMin / quaMaxPar) >= valMinPar &&
                  <span>
                    {quaMaxPar + ' x '}
                    {produtoDetalhes?.valVenMin && formatCurrency(produtoDetalhes?.valVenMin / quaMaxPar)}
                  </span>
                }
              </PrecoDiv> : <></> :
            <PrecoDiv>
              <b>
                {(produtoDetalhes?.valVenMin !== 0 && formatCurrency(produtoDetalhes?.valVenMin || 0)) || ''}
              </b>
              {quaMaxPar > 1 && (produtoDetalhes?.valVenMin / quaMaxPar) >= valMinPar &&
                <span>
                  {quaMaxPar + ' x '}
                  {produtoDetalhes?.valVenMin && formatCurrency(produtoDetalhes?.valVenMin / quaMaxPar)}
                </span>
              }
            </PrecoDiv>
          }
          <CorTamanhoDiv isMobile={isMobile}>
            {cores.length > 0 && <CoresDiv className='cores-step'>
              <span>Cor</span>
              <PaletaCoresDiv>
                <Cores setCorSelecionada={setCorSelecionada} coresLista={cores} />
              </PaletaCoresDiv>
            </CoresDiv>
            }
            {tamanhos.length > 0 && <TamanhosDiv className='tamanhos-step'>
              <span>Tamanho</span>
              <PaletaTamanhosDiv>
                <Tamanhos setTamanhoSelecionado={setTamanhoSelecionado} tamanhos={tamanhos} />
              </PaletaTamanhosDiv>
            </TamanhosDiv>
            }
          </CorTamanhoDiv>
          <hr />
          {botFinCom &&
            <>
              <br />
              <FreteDiv>
                <span>
                  CALCULE O FRETE E PRAZO DE ENTREGA
                </span>
                <FreteInputDiv>
                  <>
                    <FreteInput
                      placeholder='CEP'
                      value={cepFre} onChange={(e: any) => setCepFre(e.target.value.replace(/\D/g, ''))}
                      onBlur={(e: any) => {
                        if (e.target.value.replace(/\D/g, '').length !== 8) {
                          setCepFre('');
                        }
                      }} />
                    <FiIcons.FiSearch
                      color='#000'
                      style={{ cursor: 'pointer', width: '10%', height: '100%' }}
                      onClick={calculaFrete}
                    />
                  </>
                  {fre && <strong>&nbsp;&nbsp;{formatCurrency(fre)}</strong>}
                </FreteInputDiv>
                {mulFre && mulFre.map((mulFre: IMultiFrete, index: number) => (
                  <MultiFreteDiv key={index}>
                    <img src={mulFre.logoEmpresa} alt={`Logo Envio ${mulFre.empresa}`} />
                    <p>&nbsp;&nbsp;{mulFre.tipo}</p>
                    <p>&nbsp;&nbsp;{mulFre.prazo} dias úteis</p>
                    <strong>&nbsp;&nbsp;{formatCurrency(+mulFre.valor)}</strong>
                  </MultiFreteDiv>
                ))}
              </FreteDiv>
              <br />
              <hr />
            </>
          }
          <DescricaoProdutoDiv>
            {desSit.length > 0 && desSit.map((descricao: any, index: any) => (
              <Accordion key={index} titulo={descricao.titulo} texto={descricao.conteudo} />
            ))}
          </DescricaoProdutoDiv>
          <NavDivCarrinho>
            <QuantidadeInputDiv className='quantidade-step'>
              <QuantidadeButton
                onClick={decrementarQuantidade}
              >
                -
              </QuantidadeButton>
              <QuantidadeInput
                type={'number'}
                value={quantidade}
                onChange={alterarQuantidade}
              />
              <QuantidadeButton
                onClick={incrementarQuantidade}>
                +
              </QuantidadeButton>
            </QuantidadeInputDiv>
            <Button
              className='buttonCarrinho-step'
              backgroundColor={!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ? '#000' : '#25D366'}
              onClick={() => {
                if (import.meta.env.VITE_TITLE.toLowerCase().includes('kilar')) {
                  ComprarPeloWhatsApp();
                  return;
                }

                const codmer = produtoDetalhes?.detalhes.filter((produto: any) =>
                  produto.tamanho == tamanhoSelecionado && (produto?.cor ? produto.cor === corSelecionada.padmer : true)
                );

                addProdutoNoCarrinho({
                  codmer: codmer[0]?.codigo ?? '', codbar: produtoDetalhes?.codBar, mer: produtoDetalhes?.mer, codtam: tamanhoSelecionado, cor: corSelecionada,
                  quantidade: String(Math.floor(+quantidade)), valor: codmer[0]?.valor ?? 0, estatu: codmer[0]?.estoque
                });
              }}
              disabled={Boolean(produtoDetalhes?.esgSit)}
            >
              {!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ? isMobile ? 'Comprar' : 'Adicionar ao Carrinho' : 'WhatsApp'}
            </Button>
          </NavDivCarrinho>
          <hr />
          <AvaliacaoProduto codbar={codbar} />
        </ContainerMobile>
      }
      <Footer />
    </>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import ReactModal from 'react-modal';
import { useLocation, useNavigate } from 'react-router';
import * as AiIcons from 'react-icons/ai';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { ButtonCustom, CardContainer, CardImage, AvisoText, TextDiv, ModalInfoContainer, ModalImagesContainer, CorTamanhoDiv, CoresDiv, PaletaCoresDiv, TamanhosDiv, PaletaTamanhosDiv, QuantidadeButtonDiv } from './styles';
import Context, { ICarrinho, IContext, ICorSelecionada } from '../../context/Context';

import { formatCurrency } from '../../utils/formatCurrency';
import { toast } from 'react-toastify';
import api from '../../services/api';
import Cores from '../Cores';
import Tamanhos from '../Tamanhos';
import { QuantidadeButton, QuantidadeInput, QuantidadeInputDiv } from '../../pages/ProdutoDetalhes/styles';
import useWindowDimensions from '../../utils/WindowDimensions';
import { IIteCar, postItemCarrinho } from '../../pages/ProdutoDetalhes';
import semFoto from '../../assets/images/sem-foto.jpg';
import { gtmEventPush } from '../../utils/gtmEventPush';

interface CardProps {
  imageSrc: string;
  nome: string;
  codbar: string;
  preço: number;
  esgotado?: boolean;
}

function Modal({
  modalVisible, setModalVisible, indexCarousel,
  setIndexCarousel, quaMaxPar, fotos,
  produtoDetalhes, valMinPar, setCorSelecionada,
  cores, setTamanhoSelecionado,
  tamanhos, quantidade, setQuantidade,
  addProdutoNoCarrinho, tamanhoSelecionado, corSelecionada,
  numCel
}: any) {

  function decrementarQuantidade() {
    if (+quantidade <= 1) {
      return;
    }
    setQuantidade((prev: any) => {
      return String(+prev - 1);
    });
  }

  function incrementarQuantidade() {
    setQuantidade((prev: any) => {
      return String(+prev + 1);
    });
  }

  function alterarQuantidade(e: React.ChangeEvent<HTMLInputElement>) {
    if (+e.target.value >= 0) {
      setQuantidade(String(Math.round(+e.target.value)));
    }
  }

  function ComprarPeloWhatsApp() {
    const produtos = `Ref: ${produtoDetalhes?.codbar} ` + produtoDetalhes?.mer + ` Tamanho: ${tamanhoSelecionado}` + ` Cor: ${corSelecionada.padmer}` +
      ` Qtde: ${quantidade}` + ` Vlr Unitário: ${formatCurrency(produtoDetalhes?.valVenMin)}` + '%0A' + 'https://' + fotos[indexCarousel].linkfot;

    const url = 'https://api.whatsapp.com/send?phone=55' + numCel.replace(/\D/g, '') + '&text=Olá!! Vim pelo site e estou interessado no item:' + '%0A' + produtos;

    window.open(url);
  }

  return (
    <ReactModal
      isOpen={modalVisible}
      appElement={document.getElementById('root') as HTMLElement}
      contentLabel='Minimal Modal Example'
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setModalVisible(false)}
      style={{
        overlay: {
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 9999,
        },
        content: {
          display: 'flex',
          flexDirection: 'row',
          height: 'auto',
          maxHeight: '60%',
          maxWidth: '60%',
          margin: 'auto',
        },
      }}
    >
      <ModalImagesContainer>
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={true}
          swipeable={true}
          emulateTouch={true}
          infiniteLoop
          selectedItem={indexCarousel}
          onChange={setIndexCarousel}
          thumbWidth={fotos < 10 ? 60 : 40}
        >
          {fotos.length > 0 && fotos.map((fotos: any, index: number) => (
            <img
              key={index}
              src={fotos?.linkfot ? 'https://' + fotos.linkfot : semFoto}
              onError={(e: any) => {
                e.target.onerror = null; // Evita loops infinitos caso a imagem de substituição também não seja encontrada
                e.target.src = semFoto;
              }}
            />
          ))}
        </Carousel>
      </ModalImagesContainer>
      <ModalInfoContainer>
        <h1>
          {produtoDetalhes.mer}
        </h1>
        <p>
          Ref: {produtoDetalhes?.codBar || ''}
        </p>
        <b>
          {(produtoDetalhes?.valVenMin !== 0 && formatCurrency(produtoDetalhes?.valVenMin || 0)) || ''}
        </b>
        {quaMaxPar > 1 && (produtoDetalhes?.valVenMin / quaMaxPar) >= valMinPar &&
          <span>
            {quaMaxPar + ' x '}
            {produtoDetalhes?.valVenMin && formatCurrency(produtoDetalhes?.valVenMin / quaMaxPar)}
          </span>
        }
        <CorTamanhoDiv>
          {produtoDetalhes?.cores && produtoDetalhes.cores.length > 0 &&
            <CoresDiv>
              <span>Cor</span>
              <PaletaCoresDiv>
                <Cores setCorSelecionada={setCorSelecionada} coresLista={cores} />
              </PaletaCoresDiv>
            </CoresDiv>
          }
          {produtoDetalhes?.cores && produtoDetalhes.tamanhos.length > 0 &&
            <TamanhosDiv>
              <span>Tamanho</span>
              <PaletaTamanhosDiv>
                <Tamanhos setTamanhoSelecionado={setTamanhoSelecionado} tamanhos={tamanhos} />
              </PaletaTamanhosDiv>
            </TamanhosDiv>
          }
        </CorTamanhoDiv>
        <p dangerouslySetInnerHTML={{ __html: produtoDetalhes.desSit }} />
        <QuantidadeButtonDiv>
          <QuantidadeInputDiv>
            <QuantidadeButton
              onClick={decrementarQuantidade}
            >
              -
            </QuantidadeButton>
            <QuantidadeInput
              type={'number'}
              value={quantidade}
              onChange={alterarQuantidade}
            />
            <QuantidadeButton
              onClick={incrementarQuantidade}>
              +
            </QuantidadeButton>
          </QuantidadeInputDiv>
          <ButtonCustom
            backgroundColor={!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ? '#000' : '#25D366'}
            onClick={() => {
              if (import.meta.env.VITE_TITLE.toLowerCase().includes('kilar')) {
                ComprarPeloWhatsApp();
                return;
              }

              const codmer = produtoDetalhes?.detalhes.filter((produto: any) =>
                produto.tamanho == tamanhoSelecionado && (produto?.cor ? produto.cor === corSelecionada.padmer : true)
              );

              addProdutoNoCarrinho({
                codmer: codmer[0]?.codigo ?? '', codbar: produtoDetalhes?.codBar, mer: produtoDetalhes?.mer, codtam: tamanhoSelecionado, cor: corSelecionada,
                quantidade: String(Math.floor(+quantidade)), valor: codmer[0]?.valor ?? 0, estatu: codmer[0]?.estoque, fotos: fotos
              });
            }}
            disabled={Boolean(produtoDetalhes?.esgSit)}
          >
            {!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ? 'Adicionar ao Carrinho' : 'Compre pelo WhatsApp'}
            {!import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ?
              <AiIcons.AiOutlineShoppingCart style={{ marginLeft: 10 }} size={25} /> :
              <AiIcons.AiOutlineWhatsApp style={{ marginLeft: 10 }} size={25} />
            }
          </ButtonCustom>
        </QuantidadeButtonDiv>
      </ModalInfoContainer>
    </ReactModal >
  );
}

export default function Card({ imageSrc, nome, codbar, preço, esgotado }: CardProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;

  const {
    carrinho, setCarrinho, configs,
    dadosLogin, setSideBarCarrinhoIsOpen
  }: IContext = useContext(Context);

  //cconfigs
  const [tipoCardImagem, setTipoCardImagem] = useState('');
  const [quaMaxPar, setQuaMaxPar] = useState(1);
  const [valMinPar, setValMinPar] = useState(1);
  const [NecCadAutMosPro, setNecCadAutMosPro] = useState<boolean>(false);
  const [ApaCodBarCar, setApaCodBarCar] = useState<boolean>(false);
  const [venAciEst, setVenAciEst] = useState<boolean>(true);
  const [numCel, setNumCel] = useState<string>('');

  const [modalVisible, setModalVisible] = useState(false);
  const [produtoDetalhes, setProdutoDetalhes] = useState<any>([]);
  const [indexCarousel, setIndexCarousel] = useState<number>(0);

  //modal
  const [fotos, setFotos] = useState<any>([]);
  const [tamanhos, setTamanhos] = useState<any>([]);
  const [cores, setCores] = useState<any>([]);
  const [corSelecionada, setCorSelecionada] = useState<ICorSelecionada>({ cod: '', padmer: '', linkFot: '' });
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState<string>('');
  const [quantidade, setQuantidade] = useState<string>('1');

  function Preco() {
    if (NecCadAutMosPro) {
      if (!dadosLogin.autverprosit) {
        return (
          <AvisoText>
            Faça <u onClick={() => navigate('/login')}>Login</u> Para Visualizar o Preço
          </AvisoText>
        );
      }
    }
    return (
      <>
        <span>{formatCurrency(preço)}</span>
        {
          quaMaxPar > 0 && (preço / quaMaxPar) >= valMinPar ?
            quaMaxPar === 1 ? <b>1x de {formatCurrency(preço)}</b> : <b>{quaMaxPar}x de {formatCurrency(preço / quaMaxPar)}</b> :
            <></>
        }
      </>
    );
  }

  function handleImageMouseDown(e: any) {
    // Impedir que o evento onClick seja disparado durante o arrasto
    e?.stopPropagation();
  }

  async function getProdutoDetalhes() {
    try {
      const response = await api.get(`/mercador/listarParaDetalhes?codbar=${codbar}&CODTABPRE=0`);

      if (response.status === 200) {
        const tamanhosProd = response.data.tamanhos.map((tamanho: any) => {
          return { tamanho: tamanho, selecionado: false };
        });

        const coresProd = response.data.cores.map((cor: any) => {
          const corPorFoto = response.data.fotos.filter((foto: any) => foto.codpad === cor.cod);
          return { cod: cor.cod, padmer: cor.padmer, linkFot: corPorFoto[0]?.linkfot ? 'https://' + corPorFoto[0]?.linkfot : semFoto, selecionado: false };
        });

        setTamanhos(tamanhosProd);
        setCores(coresProd);
        setFotos(response.data?.fotos);
        setProdutoDetalhes(response.data);

        if (tamanhosProd.length === 0 && coresProd.length === 0) {
          addProdutoNoCarrinho({
            codmer: response.data.detalhes[0]?.codigo ?? '',
            codbar: response.data?.codBar,
            mer: response.data?.mer,
            codtam: tamanhoSelecionado,
            cor: corSelecionada,
            quantidade: String(Math.floor(+quantidade)),
            valor: response.data.detalhes[0]?.valor ?? 0,
            estatu: response.data.detalhes[0]?.estoque,
            fotos: response.data?.fotos
          });

          return;
        }

        gtmEventPush('view_item', {
          ecommerce: {
            currency: 'BRL',
            value: response.data?.valVenMin || 0,
            items: [{
              item_id: response.data?.detalhes[0]?.codigo,
              item_name: response.data?.mer,
              sku: codbar,
              price: response.data?.valVenMin || 0,
              item_category: '',
              id: response.data?.detalhes[0]?.codigo
            }]
          }
        });
        setModalVisible(true);
      }

    } catch (error: any) {
      toast.error('Falha ao buscar detalhes do produto ' + error.message);
    }
  }

  async function addProdutoNoCarrinho({ codmer, codbar, mer, codtam, cor, quantidade, valor, estatu, fotos }: ICarrinho) {
    const novoProduto: ICarrinho[] = [{ codmer, codbar, mer, codtam, cor, quantidade, valor, estatu }];

    if (quantidade == '0') {
      toast.warning('Quantidade inválida');
      return;
    }

    if ((produtoDetalhes?.tamanhos && produtoDetalhes.tamanhos.length > 0) && !codtam) {
      toast.warning('Selecione o tamanho');
      return;
    }

    if ((produtoDetalhes?.cores && produtoDetalhes.cores.length > 0) && !cor.cod) {
      toast.warning('Selecione a cor');
      return;
    }

    if (!valor) {
      toast.warning('Preço do produto está inválido, entre em contato com a loja.');
      return;
    }

    if (!produtoDetalhes?.cores || produtoDetalhes.cores.length === 0) {
      novoProduto[0]['cor'] = {
        cod: '', padmer: 'ÚNICA',
        linkFot: fotos[0]?.linkfot ? 'https://' + fotos[0].linkfot : semFoto
      };
    }

    if (!codmer) {
      toast.error('Código do produto não encontrado');
      return;
    }

    if (!venAciEst) {
      if (estatu === 0) {
        toast.warning('Produto fora de estoque. Estoque atual: ' + estatu);
        return;
      }

      if (!estatu) {
        toast.warning('Não foi possível verificar estoque.');
        return;
      }

      if (estatu < +quantidade) {
        toast.warning('Quantidade indisponível no estoque. Estoque atual: ' + estatu);
        return;
      }
    }

    const carrinhoAtual = carrinho;
    const [produtoJaEstaNoCarrinho] = carrinho.filter((item: ICarrinho) => item.codmer === codmer);

    if (produtoJaEstaNoCarrinho) {
      const index = carrinhoAtual.indexOf(produtoJaEstaNoCarrinho);

      if (!venAciEst) {
        if (estatu === 0) {
          toast.warning('Produto fora de estoque. Estoque atual: ' + estatu);
          return;
        }

        if (!estatu) {
          toast.warning('Não foi possível verificar estoque.');
          return;
        }

        if ((+quantidade + +(carrinhoAtual[index].quantidade)) > estatu) {
          toast.warning(`Quantidade indisponível no estoque. Já tem ${carrinhoAtual[index].quantidade} unidades no seu carrinho. Estoque atual: ${estatu}`);
          return;
        }
      }

      carrinhoAtual[index]['quantidade'] = String(+(carrinhoAtual[index].quantidade) + Math.floor(+(quantidade)));

      const itemCarrinhoAtualizado = {
        cod: carrinhoAtual[index].cod, codmer: carrinhoAtual[index].codmer,
        codapp_user: dadosLogin.id, qua: +carrinhoAtual[index].quantidade, estatu
      };

      postItemCarrinho(itemCarrinhoAtualizado);

      setCarrinho(carrinhoAtual);
      setModalVisible(false);
      setSideBarCarrinhoIsOpen(true);

      gtmEventPush('add_to_cart', {
        ecommerce: {
          currency: 'BRL',
          value: novoProduto[0].valor * +novoProduto[0].quantidade,
          items: {
            item_id: novoProduto[0].codmer,
            item_name: novoProduto[0].mer,
            sku: novoProduto[0].codbar,
            price: novoProduto[0].valor.toString(),
            item_variant: `${novoProduto[0].codbar}${novoProduto[0].cor?.padmer && novoProduto[0].cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto[0].cor.padmer : ''}${novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''}`,
            item_brand: '',
            quantity: novoProduto[0].quantidade
          }
        },
        eventModel: {
          item_id: novoProduto[0].codmer,
          item_name: novoProduto[0].mer,
          sku: novoProduto[0].codbar,
          price: novoProduto[0].valor.toString(),
          item_variant: novoProduto[0].codbar + (novoProduto[0].cor?.padmer ? ' - ' + novoProduto[0].cor.padmer : '') + (novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''),
          item_brand: '',
          quantity: novoProduto[0].quantidade
        }
      });
      return;
    }

    const novoItemCarrinho: IIteCar = { cod: undefined, codmer: novoProduto[0].codmer, codapp_user: dadosLogin.id, qua: +novoProduto[0].quantidade, estatu };

    const iteCarSalvo = await postItemCarrinho(novoItemCarrinho);

    if (iteCarSalvo) {
      novoProduto[0]['cod'] = iteCarSalvo.cod;
    }

    setCarrinho([...carrinho, ...novoProduto]);
    setModalVisible(false);
    setSideBarCarrinhoIsOpen(true);

    gtmEventPush('add_to_cart', {
      ecommerce: {
        currency: 'BRL',
        value: novoProduto[0].valor,
        items: {
          item_id: novoProduto[0].codmer,
          item_name: novoProduto[0].mer,
          sku: novoProduto[0].codbar,
          price: novoProduto[0].valor.toString(),
          item_variant: `${novoProduto[0].codbar}${novoProduto[0].cor?.padmer && novoProduto[0].cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto[0].cor.padmer : ''}${novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''}`,
          item_brand: '',
          quantity: '1'
        }
      },
      eventModel: {
        item_id: novoProduto[0].codmer,
        item_name: novoProduto[0].mer,
        sku: novoProduto[0].codbar,
        price: novoProduto[0].valor.toString(),
        item_variant: `${novoProduto[0].codbar}${novoProduto[0].cor?.padmer && novoProduto[0].cor.padmer !== 'ÚNICA' ? ' - ' + novoProduto[0].cor.padmer : ''}${novoProduto[0].codtam ? ' - ' + novoProduto[0].codtam : ''}`,
        item_brand: '',
        quantity: '1'
      }
    });
  }

  useEffect(() => {
    if (produtoDetalhes?.fotos) {
      const fotosCorSelecionada = produtoDetalhes.fotos.filter((foto: any) => foto.codpad === corSelecionada.cod);

      if (fotosCorSelecionada.length > 0) {
        setFotos(fotosCorSelecionada);
        setIndexCarousel(0);
        return;
      }

      setFotos([{
        codpad: corSelecionada.cod, linkfot: 'https://www.quitandadelivery.com/images/geral/sem_foto_big.jpg'
      }]);
      setIndexCarousel(0);
      return;
    }
  }, [corSelecionada]);

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: tipoImagem }] = configs.filter((config: any) => config.con === 'ExiTipImg');
      const [{ val: quaMaxPar }] = configs.filter((config: any) => config.con === 'quamaxpar');
      const [{ val: valminpar }] = configs.filter((config: any) => config.con === 'valminpar');
      const [{ val: CadAutMosPro }] = configs.filter((config: any) => config.con === 'NecCadAutMosPro');
      const [{ val: CodBarCar }] = configs.filter((config: any) => config.con === 'ApaCodBarCar');
      const [{ val: VenAciEst }] = configs.filter((config: any) => config.con === 'VenAciEst');
      const [{ val: numWha }] = configs.filter((config: any) => config.con === 'NumWha');

      setTipoCardImagem(tipoImagem.toLowerCase());
      setQuaMaxPar(+quaMaxPar);
      setValMinPar(+valminpar);
      setNecCadAutMosPro(Boolean(+CadAutMosPro));
      setApaCodBarCar(Boolean(+CodBarCar));
      setVenAciEst(Boolean(+VenAciEst));
      setNumCel(numWha);
    }
  }, [configs]);

  return (
    <>
      <Modal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        indexCarousel={indexCarousel}
        setIndexCarousel={setIndexCarousel}
        quaMaxPar={quaMaxPar}
        fotos={fotos}
        produtoDetalhes={produtoDetalhes}
        getProdutoDetalhes={getProdutoDetalhes}
        valMinPar={valMinPar}
        setCorSelecionada={setCorSelecionada}
        cores={cores}
        setTamanhoSelecionado={setTamanhoSelecionado}
        tamanhos={tamanhos}
        quantidade={quantidade}
        setQuantidade={setQuantidade}
        addProdutoNoCarrinho={addProdutoNoCarrinho}
        tamanhoSelecionado={tamanhoSelecionado}
        corSelecionada={corSelecionada}
        numCel={numCel}
      />
      <CardContainer>
        <CardImage
          tipoCardImagem={tipoCardImagem}
          src={imageSrc}
          alt={`Imagem do ${nome || 'Produto'}`}
          onClick={() => navigate(`/produtoDetalhes/${codbar}/${nome.replaceAll(' ', '-')}`, { state: { caminho: location.state?.caminho } })}
          onMouseDown={handleImageMouseDown}
        />
        <TextDiv>
          {(ApaCodBarCar || NecCadAutMosPro) && <span>{codbar}</span>}
          <span className='nomeProduto'>{nome}</span>
          <Preco />

          {esgotado ?
            <AvisoText>
              Esgotado
            </AvisoText> :
            (NecCadAutMosPro && !dadosLogin.autverprosit) || import.meta.env.VITE_TITLE.toLowerCase().includes('kilar') ?
              <>
              </> :
              <ButtonCustom
                backgroundColor='#000'
                onClick={() => {
                  if (isMobile) {
                    navigate(`/produtoDetalhes/${codbar}/${nome.replaceAll(' ', '-')}`, { state: { caminho: location.state?.caminho } });
                    return;
                  }

                  getProdutoDetalhes();
                }}
              >
                {isMobile ? 'Comprar' : 'Adicionar ao Carrinho'}
                <AiIcons.AiOutlineShoppingCart style={{ marginLeft: 10 }} size={25} />
              </ButtonCustom>
          }
        </TextDiv>
      </CardContainer >
    </>
  );
}
